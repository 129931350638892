<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">云龙公园无线视频监控传输项目</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云龙公园无线监控传输项目采用新诺网络科技推荐的双频无线网桥，
                针对数字化景区各种应用需求推出了领先的无线网络传输解决方案，
                满足各种应用在网络罕台上高效稳定地远行。
                现场工程师与当地SI针对公园做了更为细致和全面的现场勤察，
                结合客户实际需求提出整体解决方案。本方案总计36个监控点，
                最远监控点到控制中心距离为3 公里，设计使用无线网桥44个。<br>
                优势与特点：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、采用无线网络传输免除布线的麻烦，不需要破坏路面，建筑物。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、搭建无线网绪可以实现快速安装，大大降低人工成本，并节约时间成本。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、具有灵活的扩展性。纵快环晚再复杂多变，无线网络还可以陆时轻松改变和挪移局部的网络系统，
                这个特点尤其适合公园景区内监控点的增减和换位。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、低成本，无线网络是传统有线网络成本的1/4。在景区内，即使沟埋线或架线可以实施，
                但距离越远成本就增加非常高，主要是施工和线领的成本。而无线网络在20公里以内的成本变化很小。
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/support/banner.jpg) center no-repeat;
    background-size: 230%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 1300px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    text-align: justify;
    background-color: #ffffff;
}
</style>